import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Bug Fixes</AnchorLink>
    </AnchorLinks>
    <h2>{`Release v2021.8.13`}</h2>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Workflow execution sub-step output panels are collapsed by default, the user can expand them at will`}</li>
    </ul>
    <img {...{
      "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2021.8.13%252FCollapsible%2520log.png&isNativeBrowsing=true",
      "alt": "Collapsible log"
    }}></img>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Out of sync or stale status updates for the same components deploying in parallel`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      